// JavaScript Document

/*実行*/
const init = () => {
  telLink();
  // checkBreakPoint();
	scrollAnimation();
  checkAgree();
  var $scrollContainer;
}

//変数宣言
const pointPC = window.matchMedia('screen and (min-width: 768px)');


/* 電話番号リンク（SP用）
-------------------------------------------------- */
function telLink() {
    var ua = navigator.userAgent;
    if(ua.indexOf('iPhone') > 0 && ua.indexOf('iPod') == -1 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 && ua.indexOf('SC-01C') == -1 && ua.indexOf('A1_07') == -1 ){
        $('.js-tel-link').each(function(){
            var str = $(this).text();
            $(this).html($('<a>').attr('href', 'tel:' + str.replace(/-/g, '')).append(str + '</a>'));
        });
    }
}


/* スクロールアニメーション
-------------------------------------------------- */
function scrollAnimation() {
  var $scrollContainer;
  if (pointPC.matches) {
    // console.log("pc");
    $scrollContainer = $(".main__inner");
    scroll($scrollContainer);

  }else {
    // console.log("sp");
    $scrollContainer = $(window);
    scroll($scrollContainer);
  }

  function scroll(target) {
    const $animTrigger = $('.js-effect');

    target.on("scroll", function(){
      let windowHeight = $(this).height(); //コンテナの高さ
      let scroll = $(this).scrollTop(); //スクロールした量

      //アニメーションの実行処理
      $animTrigger.each(function(){
        let imgPos = $(this).offset().top;
        if (scroll > imgPos - windowHeight + windowHeight/5){
          //if (scroll > imgPos - windowHeight/2){
          $(this).addClass("aniOn");
        }
      });
    }).scroll();
  }
}
pointPC.addListener(scrollAnimation);


/* プライバシーポリシー同意チェック
-------------------------------------------------- */
function checkAgree() {
  const $agree = $('#agree');
  const $submit = $('#submit');
  $agree.on('change', function() {
    if ($(this).prop('checked')) {
      $submit.prop('disabled', false);
    } else {
      $submit.prop('disabled', true);
    }
  });
}

export default { init }
